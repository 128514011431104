import { ReactNode } from 'react';
import Head from 'next/head';
import classNames from 'classnames';

import { BlogPostFilter } from 'src/generated';
import Breadcrumbs, { Breadcrumb } from 'src/components/Breadcrumbs';
import Post from 'src/components/Post';
import LoadMore from 'src/components/LoadMore';
import usePostListQuery from './usePostListQuery';

type Props = {
  breadcrumbs?: Breadcrumb[];
  children?: ReactNode;
  className?: string;
  pageBottom?: ReactNode;
  title?: string;
  documentTitle?: string;
  filter?: BlogPostFilter;
};

export default function PostList({
  breadcrumbs,
  children,
  className,
  pageBottom,
  title: htmlTitle,
  documentTitle = htmlTitle,
  filter,
}: Props) {
  const { data, loading, fetchMore } = usePostListQuery(
    filter ? { filter } : undefined,
  );
  return (
    <main id='main' className={classNames('site-main', className)} role='main'>
      <Head>{documentTitle && <title>{documentTitle}</title>}</Head>

      {htmlTitle && (
        <h1
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: htmlTitle }}
        />
      )}

      {breadcrumbs && <Breadcrumbs breadcrumbs={breadcrumbs} />}

      {children}

      {data?.blogPosts?.nodes?.map((post) => (
        <Post key={post.nodePtrId} post={post} isSummary />
      ))}

      {data?.blogPosts?.pageInfo?.hasNextPage && (
        <LoadMore onClick={fetchMore} isSubmitting={loading} />
      )}

      {pageBottom}
    </main>
  );
}
