import NextLink from 'next/link';
import { useRouter } from 'next/router';

import styles from './index.module.scss';

export type Breadcrumb = {
  link?: string;
  title: string;
};

type Props = {
  breadcrumbs: Breadcrumb[];
};

export default function Breadcrumbs({ breadcrumbs }: Props) {
  const { asPath } = useRouter();
  return (
    <ol
      vocab='http://schema.org/'
      typeof='BreadcrumbList'
      className={`breadcrumbs ${styles.breadcrumbs}`}
    >
      {breadcrumbs.map((crumb, i) => (
        <li
          property='itemListElement'
          typeof='ListItem'
          key={crumb.link || crumb.title}
        >
          {!crumb.link || asPath === crumb.link ? (
            <span className='name'>{crumb.title}</span>
          ) : (
            <NextLink
              href={crumb.link}
              passHref
              property='item'
              typeof='WebPage'
            >
              <span className='name'>{crumb.title}</span>
            </NextLink>
          )}

          <meta property='position' content={String(i + 1)} />
        </li>
      ))}
    </ol>
  );
}
