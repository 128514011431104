import { useMemo } from 'react';
import { formatISO, startOfMonth } from 'date-fns';
import { setContext } from '@sentry/nextjs';
import { monthNames } from 'src/config/siteInfo';
import PostList from './index';

type Props = {
  year: number;
  month?: number;
};

export function getArchivePostListFilter({ year, month }: Props) {
  setContext('archivePostListFilterParams', { year, month });
  return {
    nodePtr: {
      publishedAt: {
        greaterThanOrEqualTo: formatISO(
          startOfMonth(new Date(year, (month || 1) - 1, 1)),
          { representation: 'date' },
        ),

        lessThan: formatISO(new Date(year, month || 12, 1), {
          representation: 'date',
        }),
      },
    },
  };
}

export default function ArchivePostList({ year, month }: Props) {
  const breadcrumbs = [
    { title: 'Archives', link: '/archive' },
    { title: String(year), link: `/${year}` },
  ];
  if (month) {
    breadcrumbs.push({
      title: monthNames[month as keyof typeof monthNames],
      link: `/${year}/${String(month).padStart(2, '0')}`,
    });
  }

  const filter = useMemo(
    () => getArchivePostListFilter({ year, month }),
    [year, month],
  );

  return (
    <PostList
      title={
        month
          ? `Month: ${monthNames[month as keyof typeof monthNames]} ${year}`
          : `Year: ${year}`
      }
      breadcrumbs={breadcrumbs}
      filter={filter}
    />
  );
}
