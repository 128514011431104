import { useQuery } from '@apollo/client';
import classNames from 'classnames';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { LinkHTMLAttributes, MetaHTMLAttributes, ReactNode } from 'react';

import SizedImage from 'src/components/SizedImage';
import StructuredField from 'src/components/StructuredField';
import { PageQueryByPageIdDocument } from 'src/generated';
import horsemanImageSizes from 'src/horsemanImageSizes';
import { getSiteUrlForPath } from 'src/utils';

import styles from './styles.module.scss';

type Params = { pageOrYear: string };

function useTitleFromSlug() {
  const { query } = useRouter();
  const { pageOrYear: slug } = query as Params;
  if (!slug) return;
  return `${slug.charAt(0).toUpperCase()}${slug
    .slice(1)
    .replace('-', ' ')
    .replace('_', ' ')}`;
}

type Props = {
  pageId?: string;
  defaultTitle?: string;
  className?: string;
  isTitleHidden?: boolean;
  children?: ReactNode;
};

export default function Page({
  pageId,
  className,
  defaultTitle,
  isTitleHidden,
  children,
}: Props) {
  const { data } = useQuery(PageQueryByPageIdDocument, {
    variables: pageId ? { pageId } : undefined,
    skip: !pageId,
  });

  const { page } = data || {};

  const titleFromSlug = useTitleFromSlug();

  const title = page ? page.title : defaultTitle || titleFromSlug;

  const link: LinkHTMLAttributes<HTMLLinkElement>[] = [];
  const meta: MetaHTMLAttributes<HTMLMetaElement>[] = [
    { property: 'og:title', content: title },
  ];
  if (page) {
    if (page.nodePtr) {
      const fullUrl = getSiteUrlForPath(page.nodePtr?.urlPath);
      link.push({ rel: 'canonical', href: fullUrl });
      meta.push({ property: 'og:url', content: fullUrl });
    }
    if (page.metaDescription) {
      meta.push(
        { name: 'description', content: page.metaDescription },
        { property: 'og:description', content: page.metaDescription },
      );
    }
  }
  return (
    <main
      id='main'
      className={classNames('site-main', 'page', className, styles.root)}
      role='main'
    >
      <Head>
        <title>{title}</title>
        {meta.map((m) => (
          <meta key={m.property || m.name} {...m} />
        ))}
        {link.map((l) => (
          <link key={l.rel} {...l} />
        ))}
      </Head>

      {!isTitleHidden ? (
        <header className='entry-header'>
          <h1 className='entry-title'>{title}</h1>
        </header>
      ) : null}

      {page?.bodyBlocksList && (
        <StructuredField
          value={page.bodyBlocksList}
          className={classNames('entry-content', styles['entry-content'])}
          itemProp='articleBody'
        />
      )}

      {page?.featuredImage && (
        <div className={classNames('entry-image', styles['entry-image'])}>
          <SizedImage
            image={page?.featuredImage}
            size={
              horsemanImageSizes['PagesPage.featuredImage'].default.blog_image
            }
          />
        </div>
      )}

      {children}
    </main>
  );
}
