import { GetStaticPropsContext } from 'next';
import { useRouter } from 'next/router';
import Page from 'src/components/Page';
import ArchivePostList, {
  getArchivePostListFilter,
} from 'src/components/PostList/Archive';
import { withPublishedFilter } from 'src/components/PostList/usePostListQuery';
import PostSidebar from 'src/components/PostSidebar';
import {
  PagePathsQueryDocument,
  PageQueryByUrlPathDocument,
  PostListQueryDocument,
} from 'src/generated';
import { addApolloState } from 'src/services/apollo/client';
import getServerApolloClient from 'src/services/apollo/getServerApolloClient';
import { getYears } from 'src/utils/yearPagePaths';

function getIsYearParam(pageOrYear: string) {
  const int = parseInt(pageOrYear, 10);
  return !!int && int >= 2010 && int < 2100;
}

type Params = { pageOrYear: string };

type Props = {
  pageId?: string;
};

export default function PageOrYear({ pageId }: Props) {
  const { query } = useRouter();

  if (getIsYearParam((query as Params).pageOrYear))
    return (
      <ArchivePostList year={parseInt((query as Params).pageOrYear, 10)} />
    );

  return <Page pageId={pageId} />;
}

PageOrYear.getSidebar = () => <PostSidebar />;

export async function getStaticPaths() {
  const client = getServerApolloClient();
  const { data } = await client.query({ query: PagePathsQueryDocument });

  const pagePaths =
    data.pagesPagesList
      ?.filter((page) => page.nodePtr?.urlPath !== '/contact/')
      ?.map((page) => {
        const [, pageOrYear] = page.nodePtr?.urlPath?.split('/') || [];
        return { params: { pageOrYear } };
      }) || [];

  const yearPaths = getYears().map((year) => ({
    params: { pageOrYear: String(year) },
  }));

  return {
    paths: [...yearPaths, ...pagePaths],
    fallback: true,
  };
}

export async function getStaticProps({
  params,
}: GetStaticPropsContext<Params>) {
  const client = getServerApolloClient();
  const isYearParam = params && getIsYearParam(params.pageOrYear);

  let props: Props = {};

  if (isYearParam) {
    await client.query({
      query: PostListQueryDocument,
      variables: {
        filter: withPublishedFilter(
          getArchivePostListFilter({
            year: parseInt(params.pageOrYear, 10),
          }),
        ),
      },
    });
  } else {
    const { data } = await client.query({
      query: PageQueryByUrlPathDocument,
      variables: params && { urlPath: `/${params.pageOrYear}/` },
    });
    const pageId = data?.pagesPagesList?.[0]?.nodePtrId;
    if (!pageId) return { notFound: true };
    props = { pageId: data?.pagesPagesList?.[0]?.nodePtrId };
  }

  return addApolloState(client, {
    props,
  });
}
